import {
  format,
  formatDistance,
  differenceInDays,
  differenceInMinutes,
  differenceInHours,
  differenceInWeeks,
  differenceInYears,
} from 'date-fns';
import { sv } from 'date-fns/locale';

export const makeFormattedTimestamp = _timestamp => {
  const now = new Date();
  const timestamp = new Date(_timestamp);

  if (differenceInDays(now, timestamp) <= 7) {
    const timeFromNow = formatDistance(timestamp, now, {
      locale: sv,
    });
    return timeFromNow;
  } else {
    const dateTime = format(timestamp, 'd MMMM', {
      locale: sv,
    });
    return dateTime;
  }
};

export const makeFormattedShortDistance = _timestamp => {
  const now = new Date();
  const timestamp = new Date(_timestamp);

  if (differenceInMinutes(now, timestamp) <= 60) {
    return differenceInMinutes(now, timestamp) + 'm';
  } else if (differenceInHours(now, timestamp) <= 24) {
    return differenceInHours(now, timestamp) + 't';
  } else if (differenceInDays(now, timestamp) <= 7) {
    return differenceInDays(now, timestamp) + 'd';
  } else if (differenceInWeeks(now, timestamp) <= 99) {
    return differenceInWeeks(now, timestamp) + 'w';
  } else {
    return differenceInYears(now, timestamp) + 'y';
  }
};

export default makeFormattedTimestamp;
