import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';
import R from 'ramda';
import Grid from '@material-ui/core/Grid';
import getSetting from '../util/getSetting';
import Layout from '../components/Layout/Layout';
import PromoItems from '../components/PromoItems/PromoItems';
import Feed from '../components/Feed/Feed';
import { Box, Avatar, Typography } from '@material-ui/core';
import { makeFormattedShortDistance } from '../util/makeFormattedTimestamp';
import Story from '../components/Story/Story';
import { getState, setState } from '../util/stateLens';

function FeedPage(props) {
  const currentStory$ = useState(null);

  const accounts = props.pageContext.data.users.edges.map(R.prop('node'));
  const promoItemsSetting = getSetting(
    'PromoItemsSetting',
    props.pageContext.data.settings.theme
  );

  function setCurrentStory(story) {
    return () => {
      setState(currentStory$, story);
    };
  }

  return (
    <Layout
      settings={props.pageContext.data.settings}
      accounts={accounts}
      location={props.location}
      title="Flöde"
    >
      {promoItemsSetting?.isEnabled &&
        promoItemsSetting.options.promoItems[0].media && (
          <React.Fragment>
            <Container>
              <Content variant="promoItems">
                <PromoItems items={promoItemsSetting.options.promoItems} />
              </Content>
            </Container>

            <Separator />
          </React.Fragment>
        )}
      {getState(currentStory$) && (
        <Story
          stories={props.pageContext.data.stories}
          accounts={accounts}
          currentStory$={currentStory$}
        ></Story>
      )}
      {!R.isEmpty(props.pageContext.data.stories) && (
        <React.Fragment>
          <Container>
            <Content variant="stories">
              <Grid container justify="space-around">
                {props.pageContext.data.stories.map((story) => {
                  const thumbnailUrl =
                    story.type == 'VIDEO' ? story.thumbnailUrl : story.url;
                  const ago = makeFormattedShortDistance(story.date);

                  return (
                    <Grid item onClick={setCurrentStory(story)}>
                      <Box textAlign="center">
                        <ThumbnailBorder>
                          <Thumbnail src={thumbnailUrl} elevation={5} />
                        </ThumbnailBorder>
                        {/* <Text>{ago}</Text> */}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Content>
          </Container>
          <Separator />
        </React.Fragment>
      )}
      <Container>
        <Content role="feed" variant="feed">
          <Feed
            feed={props.pageContext.data.feed}
            accounts={accounts}
            apiUrl={props.pageContext.data.apiUrl}
            totalFeedItems={props.pageContext.data.totalFeedItems}
            initialFeedLength={props.pageContext.data.feed.length}
          ></Feed>
        </Content>
      </Container>
    </Layout>
  );
}

FeedPage.propTypes = {
  location: PropTypes.object.isRequired,
};

const Container = styled('div')(({ theme }) => ({
  padding: `0 ${theme.spacing(3)}px`,
  [theme.breakpoints.up('sm')]: {
    padding: `0 ${theme.spacing(6)}px`,
  },
  [theme.breakpoints.up('lg')]: {
    padding: `0 ${theme.spacing(8)}px`,
  },
}));

const Separator = styled('div')(({ theme }) => ({
  borderBottom: '1px solid #efefef',
  // marginBottom: theme.spacing(3),
  // paddingBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    border: 'none',
    // marginBottom: '4vw',
    // paddingBottom: '4vw',
  },
  [theme.breakpoints.up('lg')]: {
    // marginBottom: theme.spacing(8),
    // paddingBottom: theme.spacing(7),
  },
}));

const Thumbnail = styled(Avatar)(({ theme }) => ({
  height: 56,
  width: 56,
  borderWidth: 2,
  borderColor: theme.palette.common.white,
  borderStyle: 'solid',
  backgroundColor: theme.palette.common.white,
  cursor: 'pointer',
}));

const ThumbnailBorder = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  padding: 2,
  backgroundImage: 'conic-gradient(#1934A1, #17E5DC, #EE09C6, #1934A1)',
}));

const Text = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontWeight: 500,
  lineHeight: 1,
  fontSize: theme.typography.caption.fontSize,
  color: theme.palette.caption,
}));

const Content = styled('div')(({ theme, maxWidth, variant }) => ({
  maxWidth: 500,
  margin: '0 auto',
  ...(variant == 'promoItems' && {
    padding: `${theme.spacing(3)}px 0`,
    [theme.breakpoints.up('sm')]: {
      padding: 0,
      marginBottom: theme.spacing(4),
      maxWidth: 500,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(6),
      // maxWidth: '90%',
      maxWidth: 670,
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: '65%',
      // maxWidth: 670,
    },
    [theme.breakpoints.up('xl')]: {
      // marginBottom: theme.spacing(8),
      // maxWidth: 1280,
    },
  }),
  ...(variant == 'stories' && {
    padding: `${theme.spacing(2)}px 0`,
  }),
  ...(variant == 'feed' && {
    padding: `${theme.spacing(4)}px 0`,
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(8)}px 0`,
    },
  }),
}));

// const PromoItemsConstrainer = styled('div')(({ theme }) => ({
//   margin: '0 auto',
//   [theme.breakpoints.up('sm')]: {
//     maxWidth: 500,
//   },
//   [theme.breakpoints.up('md')]: {
//     maxWidth: '90%',
//   },
//   [theme.breakpoints.up('lg')]: {
//     maxWidth: '85%',
//   },
//   [theme.breakpoints.up('xl')]: {
//     maxWidth: 1280,
//   },
// }));

export default FeedPage;
