import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import { styled } from '@material-ui/styles';
import { Avatar, CardMedia, Typography, Box } from '@material-ui/core';
import { getState, setState } from '../../util/stateLens';
import { makeFormattedTimestamp } from '../../util/makeFormattedTimestamp';
import MuiCloseIcon from '@material-ui/icons/Close';

function Story(props) {
  const currentStory = getState(props.currentStory$);
  const isVideo = currentStory.type == 'VIDEO';
  const mediaComponent = isVideo ? 'video' : 'div';
  const playLength = currentStory.length ? currentStory.length * 1000 : 7500;
  const storyTimeout = useRef(null);
  const isPlaying$ = useState(false);

  const currentStoryAccount = props.accounts.find(
    ({ remoteId }) => remoteId == currentStory.remoteAccountId
  );

  useEffect(() => {
    return handleStoryTimeout(currentStory, isPlaying$);
  }, [currentStory, isPlaying$]);

  useEffect(() => {
    if (currentStory) {
      if (isVideo) {
        setState(isPlaying$, true);
      } else {
        setState(isPlaying$, true);
      }
    }
  }, [currentStory]);

  /**
   * Close the story.
   */
  function handleClose() {
    setState(props.currentStory$, null);
  }

  /**
   * Go to next story.
   *
   * If there is no next story, close the current one.
   */
  function handleNext() {
    setState(isPlaying$, false);

    const currentStoryIndex = R.indexOf(currentStory, props.stories);

    if (currentStoryIndex == props.stories.length - 1) {
      handleClose();
    } else {
      setState(props.currentStory$, props.stories[currentStoryIndex + 1]);
    }
  }

  /**
   * Sets and clears the timeout for the story.
   *
   * If the timeout has reached its end, trigger a click event on the story.
   */
  function handleStoryTimeout(currentStory, isPlaying$) {
    if (currentStory && getState(isPlaying$)) {
      const newStoryTimeout = setTimeout(handleNext, playLength);

      if (storyTimeout?.current) {
        clearTimeout(storyTimeout.current);
      }

      storyTimeout.current = newStoryTimeout;
    }

    return () => {
      clearTimeout(storyTimeout.current);
    };
  }

  return (
    <Root>
      {/* <Backdrop> */}
      <Inner>
        <ProgressBars>
          {props.stories.map((story, index) => {
            const isPlaying =
              story.remoteId == currentStory.remoteId && getState(isPlaying$);
            const wasPlayed =
              R.findIndex(
                R.propEq('remoteId', currentStory.remoteId),
                props.stories
              ) > index;

            return (
              <ProgressBar>
                <ProgressBarFill
                  isPlaying={isPlaying}
                  wasPlayed={wasPlayed}
                  playLength={playLength}
                ></ProgressBarFill>
              </ProgressBar>
            );
          })}
        </ProgressBars>
        <Profile>
          <ProfilePicture
            src={currentStoryAccount.profilePictureUrl}
          ></ProfilePicture>
          <Box>
            <Name variant="body2">{currentStoryAccount.name}</Name>
            <TimeAgo variant="caption">
              {makeFormattedTimestamp(currentStory.date)}
            </TimeAgo>
          </Box>
        </Profile>
        <Close onClick={handleClose}></Close>
        {isVideo && (
          <Media src={currentStory.thumbnailUrl} component="div"></Media>
        )}
        <Media
          src={currentStory.url}
          autoPlay
          component={mediaComponent}
          onClick={handleNext}
        ></Media>
      </Inner>
      {/* </Backdrop> */}
    </Root>
  );
}

Story.propTypes = {
  stories: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  bottom: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.7)',
  zIndex: theme.zIndex.modal,
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Inner = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 375,
    height: 667,
  },
}));

const Media = styled(CardMedia)(({ theme, src }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const ProgressBars = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(0.75),
  right: theme.spacing(0.75),
  display: 'flex',
  zIndex: 1,
}));

const ProgressBar = styled('div')(({ theme, wasPlayed }) => ({
  height: 3,
  background: 'rgba(255, 255, 255, 0.3)',
  flexGrow: 1,
  margin: `0 ${theme.spacing(0.25)}px`,
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
}));

const ProgressBarFill = styled('div')(
  ({ theme, wasPlayed, isPlaying, playLength }) => ({
    transition: `width ${playLength / 1000}s linear`,
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.7)',
    top: 0,
    left: 0,
    bottom: 0,
    width: 0,
    ...(isPlaying && {
      width: '100%',
    }),
    ...(wasPlayed && {
      width: '100%',
      transition: 'none',
    }),
  })
);

const Profile = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  left: theme.spacing(2),
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
}));

const ProfilePicture = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 600,
  lineHeight: 1,
}));

const TimeAgo = styled(Typography)(({ theme }) => ({
  color: 'rgba(255,255,255,0.7)',
  lineHeight: 1,
}));

const Close = styled(MuiCloseIcon)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(3.5),
  color: 'rgba(255,255,255,0.7)',
  zIndex: 1,
  fontSize: 32,
}));

export default Story;
