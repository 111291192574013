import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import R from 'ramda';
import { styled } from '@material-ui/styles';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getState, setState, updateState } from '../../util/stateLens';
import Avatar from '@material-ui/core/Avatar';
import MuiToolbar from '@material-ui/core/Toolbar';
import MuiCard from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import MuiCardMedia from '@material-ui/core/CardMedia';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ReCarousel from 're-carousel';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRightRounded';
import activeHtml from 'react-active-html';
import VolumeIcon from '../Icons/VolumeIcon/VolumeIcon';
import MediaStartIcon from '../Icons/MediaStartIcon/MediaStartIcon';
import LikeIcon from '../Icons/LikeIcon/LikeIcon';
import CommentIcon from '../Icons/CommentIcon/CommentIcon';
import getImageOrVideoUrl from '../../util/getImageOrVideoUrl';
import makeFormattedTimestamp, {
  makeFormattedShortDistance,
} from '../../util/makeFormattedTimestamp';
import makeContentComponentsMap from '../../util/makeContentComponentsMap';
import TrackVisibility from 'react-on-screen';
import CarouselPluginIndicatorDots from './CarouselPlugins/CarouselIndicatorDots';

const GET_MEDIA = gql`
  query getMedia($origin: String!, $mediaId: String!) {
    remoteMedia(origin: $origin, mediaId: $mediaId) {
      url
    }
  }
`;

/**
 * Load separate DOMParser if run on node
 */
if (typeof window === 'undefined') {
  const xmldom = require('xmldom');
  activeHtml.DOMParser = new xmldom.DOMParser();
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: 12,
    padding: 2,
    borderWidth: 1,
    width: 44,
    height: 44,
    borderColor: theme.palette.divider,
    boxShadow: theme.shadows[1],
  },
  avatarImg: {
    borderRadius: '100%',
  },
  focusNoHighlight: {
    display: 'none',
  },
}));

const FeedItem = (props) => {
  const [getMedia, getMedia$] = useLazyQuery(GET_MEDIA, {
    variables: {
      origin: props.item.origin,
      mediaId: props.item.media?.remoteId,
    },
  });

  const formattedTimestamp = makeFormattedTimestamp(props.item.date);
  const [isExcerptExpanded, setIsExcerptExpanded] = useState(false);
  const [isRemoteActionsDialogOpen, setIsRemoteActionsDialogOpen] = useState(
    false
  );
  const [$mediaActionArea, set$MediaActionArea] = useState(null);
  const mediaPlaying$ = useState({
    isLoading: false,
    isPlaying: false,
    isMuted: false,
    isCarouselMedia: false,
    carouselMediaIndex: null,
  });

  const $videoMedia$ = useState(null);
  const [isVideoThumbnailClicked, setIsVideoThumbnailClicked] = useState(null);
  const currentCarouselIndex$ = useState(0);
  const [postItems, setPostItems] = useState(props.item.items);
  const mediaUrl$ = useState(props.item.media?.url);
  const showAllComments$ = useState(false);

  const hasLikes = !!parseInt(props.item.likeCount);
  const hasComments = !!parseInt(props.item.commentCount);
  const hasInteractions = hasLikes || hasComments;
  const visibleComments = getVisibleComments(props.item.comments);

  const isCarousel = props.item.type == 'MEDIA_CAROUSEL';
  const isVideo = props.item.type == 'VIDEO';
  const hasMedia = props.item.media || props.item.items;
  const hasContent = !R.isEmpty(props.item.content.rendered);

  const isFbOrigin = props.item.origin == 'fb';

  /**
   * Sets a reference to the $video element and plays it
   */
  useEffect(() => {
    if (isVideoThumbnailClicked) {
      const $video = $mediaActionArea.querySelector('video');

      setState($videoMedia$, $video);
      playVideo($video);
    }
  }, [$mediaActionArea, isVideoThumbnailClicked]);

  /**
   * Takes care of media states
   */
  useEffect(() => {
    if (getState($videoMedia$)) {
      const { isPlaying, isMuted } = getState(mediaPlaying$);

      /**
       * Handle playing state
       */
      if (isPlaying) {
        getState($videoMedia$).play();
      } else {
        getState($videoMedia$).pause();
      }

      /**
       * Handle muted state
       */
      getState($videoMedia$).muted = isMuted;
    }
  }, [mediaPlaying$, $videoMedia$]);

  /**
   * Set remote media url if fetched.
   */
  if (getMedia$.data?.remoteMedia?.url && !getState(mediaUrl$)) {
    setState(mediaUrl$, getMedia$.data.remoteMedia.url);
  }

  /**
   * The full content, accepts a htmlString prop which will get mapped to
   * React components using the defaultComponentsMap and a specific content one.
   * @param {React.Props} props
   */
  function Content(props) {
    const paragraphVariant = props.postType == 'BLOG' ? 'body1' : 'body2';
    const paragraphComponentMap = {
      p: (props) => (
        <ContentParagraph
          variant={paragraphVariant}
          paragraph
          style={{ lineHeight: '1.7' }}
          {...props}
        />
      ),
    };

    const contentComponentsMap = makeContentComponentsMap(
      paragraphComponentMap
    );

    const contentNodes = activeHtml(props.htmlString, contentComponentsMap);

    return <div>{contentNodes}</div>;
  }

  Content.propTypes = {
    htmlString: PropTypes.string.isRequired,
  };

  /**
   * Make a html string from a social tag name and type
   * @param {String} name the name of the tag, without its symbol
   * @param {String} symbol the symbol, e.g. #or @
   */
  function makeSocialTagLink(name, symbol) {
    return `<a href="#" data-tag-name="${name}" data-tag-symbol="${symbol}">${symbol}${name}</a>`;
  }

  /**
   * Takes a string with hashtags and/or mentions and wraps them with a-tags
   * @see https://stackoverflow.com/a/10995892
   * @param {String} string
   */
  function convertSocialTagsToLinks(string) {
    const re = new RegExp([' #([a-z0-9]+)', ' @([a-z0-9]+)'].join('|'), 'gi');

    return string.replace(re, (match, hashtag, mention) => {
      if (hashtag) {
        return ' ' + makeSocialTagLink(hashtag, '#');
      } else if (mention) {
        return ' ' + makeSocialTagLink(mention, '@');
      }

      // shouldnt get here, but just in case
      return match;
    });
  }

  /**
   * The content excerpt, accepts a htmlString prop which will get mapped to
   * React components using the defaultComponentsMap and a specific excerpt one.
   * @param {React.Props} props
   */
  function Excerpt(props) {
    const paragraphVariant = props.postType == 'BLOG' ? 'body1' : 'body2';
    const paragraphComponentMap = {
      p: (props) => (
        <Box clone display="inline">
          <Typography
            component="span"
            variant={paragraphVariant}
            style={{ lineHeight: '1.7' }}
            {...props}
          />
        </Box>
      ),
    };

    const contentComponentsMap = makeContentComponentsMap(
      paragraphComponentMap
    );

    const htmlStringWithLinks = convertSocialTagsToLinks(props.htmlString);
    const excerptNodes = activeHtml(htmlStringWithLinks, contentComponentsMap);

    return (
      <Typography variant={paragraphVariant} style={{ lineHeight: '1.7' }}>
        {excerptNodes}
        <ExcerptMoreLink
          component="a"
          display="inline"
          onClick={() => setIsExcerptExpanded(true)}
        >
          &nbsp;Mer
        </ExcerptMoreLink>
      </Typography>
    );
  }

  Excerpt.propTypes = {
    htmlString: PropTypes.string.isRequired,
  };

  /**
   * Open the remote actions dialog by setting it's open state to true.
   */
  function openRemoteActionsDialog() {
    if (props.item.isRemoteOrigin) {
      setIsRemoteActionsDialogOpen(true);
    } else {
      // TODO: Make a reveal animation so that users dont think its a page change
      // setIsExcerptExpanded(true)
    }
  }

  /**
   * Close the remote actions dialog by setting it's open state to false.
   */
  function closeRemoteActionsDialog() {
    setIsRemoteActionsDialogOpen(false);
  }

  /**
   * Navigates the browser to the account URL.
   */
  function goToRemotePost() {
    window.location.href = props.item.remotePermalinkUrl;
  }

  /**
   * Navigates the browser to the account URL.
   */
  function goToRemoteAccount() {
    console.log('props.account :>> ', props.account);
    // window.location.href = props.account.permalinkUrl;
  }

  /**
   * Plays the referenced DOM video.
   * @param {React.DOMElement} $video
   */
  const playVideo = ($video) => {
    updateState(mediaPlaying$, {
      isPlaying: true,
      isLoading: true,
      isCarouselMedia: isCarousel,
      carouselMediaIndex: isCarousel ? getState(currentCarouselIndex$) : null,
    });
  };

  /**
   * Pauses the referenced DOM video.
   * @param {React.DOMElement} $video
   */
  function pauseVideo($video) {
    updateState(mediaPlaying$, {
      isPlaying: false,
      isLoading: false,
      isCarouselMedia: isCarousel,
      carouselMediaIndex: null,
    });
  }

  /**
   * Stops the vieo from playing and sets the start time to the beginning.
   */
  function stopVideo() {
    pauseVideo(getState($videoMedia$));
    getState($videoMedia$).currentTime = 0;
  }

  /**
   * Toggle the media playing state and call the play/pause functions with
   * a reference to the saved DOM reference to the video media.
   */
  function toggleMediaPlayingState() {
    if (getState(mediaPlaying$).isPlaying) {
      pauseVideo(getState($videoMedia$));
    } else {
      playVideo(getState($videoMedia$));
    }
  }

  /**
   * Fetches Facebook videos from the server, since video URLs from Facebook
   * change overtime, we need to refetch the video each time we want a URL.
   */
  async function fetchFbVideo() {
    return axios.get(
      `${props.apiUrl}/wp-json/doi/v1/fb/media?id=${props.item.mediaId}`
    );
  }

  /**
   * If the thumbnail hasn't been clicked yet, first set the state indicating
   * that the thumbnail has been clicked to true, then, save the DOM reference
   * of the video wrapper so that we can access the DOM video from it. If the
   * video thumbnail has already been clicked, just toggle the playing state.
   * @param {React.MouseEvent} event
   */
  async function toggleVideoMediaPlayingState(event) {
    if (!isVideoThumbnailClicked) {
      setIsVideoThumbnailClicked(true);
      updateState(mediaPlaying$, {
        isLoading: true,
      });
      set$MediaActionArea(event.currentTarget);

      if (isFbOrigin) {
        getMedia();
      }
    } else {
      toggleMediaPlayingState();
    }
  }

  /**
   * Sets the current post item as isClicked when the thumbnail is clicked
   */
  function setCurrentPostItemIsClicked() {
    const amendedPostItems = postItems.map((postItem, index) => {
      if (getState(currentCarouselIndex$) == index) {
        return {
          type: postItem.type,
          item: {
            ...postItem.item,
            isVideoThumbnailClicked: true,
          },
        };
      } else {
        return postItem;
      }
    });

    setPostItems(amendedPostItems);
  }

  /**
   * Handles playing and pausing videos
   * @param {React.MouseEvent} event
   */
  function onMediaItemClick(event) {
    console.log(props.item);
    if (isVideo) {
      toggleVideoMediaPlayingState(event);
    } else if (isCarousel) {
      const carouselMediaItem =
        props.item.items[getState(currentCarouselIndex$)].item;

      if (carouselMediaItem.type == 'VIDEO') {
        setCurrentPostItemIsClicked();
        toggleVideoMediaPlayingState(event);
      }
    } else {
      openRemoteActionsDialog();
    }
  }

  /**
   * Shows all comments
   * @todo implement
   */
  function showAllComments() {
    setState(showAllComments$, true);
  }

  /**
   * Gets visible comments, shows all comments depending on its state and
   * amount of comments, it could also return a comment reply or nothing.
   * @param {Comment[]} comments
   * @returns {Comment[]}
   */
  function getVisibleComments(comments) {
    if (!comments) {
      return [];
    } else if (
      getState(showAllComments$) ||
      parseInt(props.item.commentCount) < 4
    ) {
      return comments;
    } else {
      const firstFiveComments = comments.slice(0, 5);
      const commentWithReplies = firstFiveComments.find(
        ({ replies }) => replies && replies.length < 4
      );

      if (commentWithReplies) {
        return [commentWithReplies];
      } else {
        return [];
      }
    }
  }

  /**
   * Used to track if a component is fully scrolled out of viewport and if so
   * it will run a function, currently its pausing the video.
   * @todo refactor jest workaround in tests instead of here
   * @see https://github.com/fkhadra/react-on-screen/issues/43
   * @param {React.Component} Component
   * @returns {React.ReactElement}
   */
  function withPauseMediaItemWhenScrolledOutOfView(Component, props) {
    return (
      <TrackVisibility partialVisibility>
        {({ isVisible }) => {
          if (!isVisible && getState(mediaPlaying$).isPlaying) {
            if (process && process.env && process.env.NODE_ENV == 'test') {
            } else {
              pauseVideo(getState($videoMedia$));
            }
          }
          return <Component shouldLoadImage={isVisible} {...props} />;
        }}
      </TrackVisibility>
    );
  }

  /**
   * Dialog that contains actions to remote account/post
   * @param {React.Props} props
   */
  function RemoteActionsDialog(props) {
    return (
      <Dialog
        open={props.open}
        onClose={closeRemoteActionsDialog}
        fullWidth={true}
        maxWidth="xs"
      >
        <List
          subheader={
            <ListSubheader component="div">Vad vill du göra?</ListSubheader>
          }
        >
          <ListItem button onClick={goToRemotePost}>
            <ListItemText primary="Gå till orginalinlägget" />
            <KeyboardArrowRight />
          </ListItem>
          {/* <ListItem button onClick={goToRemoteAccount}>
            <ListItemAvatar>
              <Avatar src={props.account.profilePictureUrl} />
            </ListItemAvatar>
            <ListItemText primary="Gå till profil" />
            <KeyboardArrowRight />
          </ListItem> */}
        </List>
        <DialogActions>
          <Button color="primary" onClick={closeRemoteActionsDialog}>
            Stanna
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Root elevation="0">
      <Post>
        {props.item.isRemoteOrigin && props.account && (
          <RemoteActionsDialog
            open={isRemoteActionsDialogOpen}
            account={props.account}
          />
        )}
        <Box mb={hasContent ? 0 : 1}>
          <Header
            postType={props.item.type}
            account={props.account}
            title={props.item.title}
            formattedTimestamp={formattedTimestamp}
            openRemoteActionsDialog={openRemoteActionsDialog}
          />
        </Box>
        {hasContent && (
          <Box mb={hasMedia ? 2 : 1}>
            <Body>
              {props.item.content.rendered &&
                (isExcerptExpanded ||
                props.item.isExcerptSameLengthAsContent ? (
                  <Content
                    postType={props.item.type}
                    htmlString={
                      props.item.content.rendered || props.item.content
                    }
                  />
                ) : (
                  <Excerpt
                    postType={props.item.type}
                    htmlString={
                      props.item.excerpt.rendered || props.item.excerpt
                    }
                  />
                ))}
            </Body>
          </Box>
        )}
        {hasMedia &&
          withPauseMediaItemWhenScrolledOutOfView(MediaItemOrCarousel, {
            ...props.item,
            items: postItems,
            stopVideo,
            playVideo,
            pauseVideo,
            onMediaItemClick,
            isVideoThumbnailClicked,
            $videoMedia$,
            mediaPlaying$,
            mediaUrl$,
            currentCarouselIndex$,
          })}
        {hasInteractions && (
          <Interactions areCommentsVisible={!R.isEmpty(visibleComments)}>
            {hasLikes && (
              <Interaction role="button">
                <Box mr={1}>
                  <LikeIcon />
                </Box>
                {props.item.likeCount}
              </Interaction>
            )}
            {hasComments && (
              <Interaction onClick={showAllComments} role="button">
                <Box mr={1}>
                  <CommentIcon />
                </Box>
                {(!getState(showAllComments$) &&
                parseInt(props.item.commentCount) > 3
                  ? // Hur va de?
                    'Visa '
                  : '') +
                  `${props.item.commentCount} kommentar` +
                  (parseInt(props.item.commentCount) > 1 ? 'er' : '')}
              </Interaction>
            )}
          </Interactions>
        )}
      </Post>
      {!R.isEmpty(visibleComments) && (
        // <TrackVisibility partialVisibility once offset={-80}>
        //   {({ isVisible }) => {
        // return (
        <Comments>
          <CommentsInner>
            {/* <CommentsInner presentComments={isVisible}> */}
            {visibleComments.map((comment) => (
              <React.Fragment key={comment.remoteId}>
                <Comment
                  owner={comment.owner}
                  date={comment.date}
                  content={comment.content}
                ></Comment>
                {comment.replies &&
                  comment.replies.map((reply) => (
                    <Comment
                      owner={reply.owner}
                      date={reply.date}
                      content={reply.content}
                    ></Comment>
                  ))}
              </React.Fragment>
            ))}
          </CommentsInner>
        </Comments>
        // )
        //   }}
        // </TrackVisibility>
      )}
    </Root>
  );
};

FeedItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

function Comment(props) {
  return (
    <CommentInner owner={props.owner}>
      <CommentDate owner={props.owner}>
        {makeFormattedShortDistance(props.date)}
      </CommentDate>
      <CommentBubble>
        <CommentBubbleInner owner={props.owner}>
          {props.content}
        </CommentBubbleInner>
      </CommentBubble>
      <CommentWhitespace></CommentWhitespace>
    </CommentInner>
  );
}

/**
 * The blog post header differs from the remote post headers in that
 * it doesn't contain a profile picture and links directly to the post.
 * @param {React.Props} props
 */
function BlogPostHeader(props) {
  const classes = useStyles();

  /**
   * Navigates to the blog post
   * @todo implement navigation logic
   */
  function goToBlogPost() {}

  return (
    <CardActionArea
      classes={{ focusHighlight: classes.focusNoHighlight }}
      onClick={goToBlogPost}
    >
      <Toolbar disableGutters>
        <div>
          <BlogPostTitle gutterBottom variant="h6">
            {props.title.rendered}
          </BlogPostTitle>
          <PostedOn variant="caption">{props.formattedTimestamp}</PostedOn>
        </div>
      </Toolbar>
    </CardActionArea>
  );
}

BlogPostHeader.propTypes = {
  title: PropTypes.string.isRequired,
  formattedTimestamp: PropTypes.string.isRequired,
};

/**
 * The remote post header contains a profile picture and account name
 * and when clicked opens a modal with further actions to take.
 * @param {React.Props} props
 */
function RemotePostHeader(props) {
  const classes = useStyles();

  return (
    <CardActionArea
      classes={{ focusHighlight: classes.focusNoHighlight }}
      onClick={props.onClick}
    >
      <Toolbar disableGutters>
        <Avatar
          alt={props.account.name}
          src={props.account.profilePictureUrl}
          elevation="5"
          classes={{
            root: classes.avatar,
            img: classes.avatarImg,
          }}
        />
        <div>
          <Title gutterBottom variant="subtitle1">
            {props.account.name}
          </Title>
          <PostedOn variant="caption">{props.formattedTimestamp}</PostedOn>
        </div>
      </Toolbar>
    </CardActionArea>
  );
}

RemotePostHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  formattedTimestamp: PropTypes.string.isRequired,
};

/**
 * The post header
 * @param {React.Props} props
 */
function Header(props) {
  if (props.postType == 'BLOG' || !props.account) {
    return (
      <BlogPostHeader
        title={props.title}
        formattedTimestamp={props.formattedTimestamp}
      />
    );
  } else {
    return (
      <RemotePostHeader
        account={props.account}
        onClick={props.openRemoteActionsDialog}
        formattedTimestamp={props.formattedTimestamp}
      />
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
  account: PropTypes.string,
  formattedTimestamp: PropTypes.string.isRequired,
};

/**
 * Creates a single media item or a carousel of media items
 * @param {React.Props} props
 */
function MediaItemOrCarousel(props) {
  if (!props.items) {
    const isVideo = props.type == 'VIDEO';

    return (
      <MediaItem
        item={props.media}
        onClick={props.onMediaItemClick}
        showVideo={isVideo && props.isVideoThumbnailClicked}
        // origin={props.origin}
        // type={props.type}
        // height={props.mediaHeight}
        // width={props.mediaWidth}
        mediaUrl$={props.mediaUrl$}
        shouldLoadImage={props.shouldLoadImage}
        mediaPlaying$={props.mediaPlaying$}
      />
    );
  } else {
    return (
      <MediaCarousel
        items={props.items}
        origin={props.origin}
        onClick={props.onMediaItemClick}
        mediaPlaying$={props.mediaPlaying$}
        $videoMedia$={props.$videoMedia$}
        currentCarouselIndex$={props.currentCarouselIndex$}
      />
    );
  }
}

/**
 * This component can be used etiher as standalone or as an item inside of a
 * carousel, it supports, both videos and images, portrait and landscape.
 * @todo refactor all playing state logic inside this component
 * @param {React.Props} props
 */
function MediaItem(props) {
  const imageUrl = getImageOrVideoUrl(props.item);
  const isFbOrigin = props.item.origin == 'fb';
  const isVideo = props.item.type == 'VIDEO';
  const shouldShowMediaStartIcon =
    isVideo && !getState(props.mediaPlaying$).isPlaying;
  const isVerticalFbVideo =
    isVideo && isFbOrigin && props.item.height > props.item.width;

  /**
   * Sets the media loading state to false, thus hiding the indicator
   */
  function hideMediaLoadingProgress() {
    updateState(props.mediaPlaying$, {
      isLoading: false,
    });
  }

  /**
   * Mutes or unmutes video
   */
  function toggleIsMuted() {
    updateState(props.mediaPlaying$, {
      isMuted: !getState(props.mediaPlaying$).isMuted,
    });
  }

  function getInnerRole() {
    if (props.isInMediaCarousel) {
      if (isVideo) {
        return 'button';
      } else {
        return 'presentation';
      }
    } else {
      return 'button';
    }
  }

  return (
    <MediaItemContainer
      isVerticalVideo={isVerticalFbVideo}
      isInMediaCarousel={props.isInMediaCarousel}
    >
      <MediaItemInner
        onClick={props.onClick}
        role={getInnerRole()}
        data-testid="media-item-inner"
      >
        <MediaItemImage
          isVerticalVideoBackground={isVerticalFbVideo}
          isInMediaCarousel={props.isInMediaCarousel}
          component={isVerticalFbVideo ? 'div' : 'img'}
          src={imageUrl}
          // src={props.shouldLoadImage && imageUrl}
        />
        {isVerticalFbVideo && (
          <MediaItemVerticalVideoThumbnail src={imageUrl} component="div" />
        )}
        {props.showVideo && (
          <MediaItemVideo
            isInMediaCarousel={props.isInMediaCarousel}
            isVertical={isVerticalFbVideo}
            data-testid="media-video"
            src={
              props.isInMediaCarousel
                ? props.item.url
                : getState(props.mediaUrl$)
            }
            onPlaying={hideMediaLoadingProgress}
            playsInline
            component="video"
            loop
          />
        )}
        {getState(props.mediaPlaying$).isLoading && (
          <MediaItemProgress>
            <MuiCircularProgress color="white" />
          </MediaItemProgress>
        )}
        {shouldShowMediaStartIcon && (
          <Box data-testid="media-start-icon">
            <MediaStartIcon />
          </Box>
        )}
      </MediaItemInner>
      {getState(props.mediaPlaying$).isPlaying && (
        <Box data-testid="media-mute-icon">
          <VolumeIcon
            isMuted={getState(props.mediaPlaying$).isMuted}
            onClick={toggleIsMuted}
            role="button"
          />
        </Box>
      )}
    </MediaItemContainer>
  );
}

MediaItem.propTypes = {
  item: PropTypes.object.isRequired,
  isInMediaCarousel: PropTypes.bool,
};

/**
 * Äta karamell
 * @param {React.Props} props
 */
function MediaCarousel(props) {
  const mediaItems = props.items.map((item, index) => {
    const isVideo = item.item.type == 'VIDEO';

    return (
      <MediaItem
        item={item.item}
        // type={mediaItem.type}
        showVideo={isVideo && item.item.isVideoThumbnailClicked}
        onClick={props.onClick}
        // origin={props.origin}
        mediaPlaying$={props.mediaPlaying$}
        isInMediaCarousel
        key={item.item.remoteId}
      />
    );
  });

  /**
   * Helper component to set current carousel index, returns nothing
   * @param {React.Props} carouselProps
   */
  function _setCurrentCarouselIndex(carouselProps) {
    setState(props.currentCarouselIndex$, carouselProps.index);

    if (
      getState(props.mediaPlaying$).isPlaying &&
      getState(props.mediaPlaying$).isCarouselMedia &&
      getState(props.mediaPlaying$).carouselMediaIndex != carouselProps.index
    ) {
      updateState(props.mediaPlaying$, {
        isPlaying: false,
      });

      setState(props.$videoMedia$, null);
    }

    return null;
  }

  return (
    <CarouselContainer>
      <Carousel
        widgets={[CarouselPluginIndicatorDots, _setCurrentCarouselIndex]}
        frames={mediaItems}
      />
    </CarouselContainer>
  );
}

MediaCarousel.propTypes = {
  items: PropTypes.array.isRequired,
};

const Root = styled(MuiCard)(({ theme }) => ({
  marginBottom: theme.spacing.unit * 4,
  overflow: 'visible',
  maxWidth: 645 - 48,
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing.unit * 6,
  },
}));

const Post = styled('div')(({ theme }) => ({
  position: 'relative',
  background: 'rgba(255, 255, 255, 0.8)',
  zIndex: 1,
}));

const Toolbar = styled(MuiToolbar)({
  minHeight: 48,
});

const Title = styled(Typography)({
  lineHeight: 1,
});

const BlogPostTitle = styled(Typography)({
  lineHeight: 1.35,
});

const PostedOn = styled(Typography)({
  lineHeight: 1,
});

const Body = styled(CardContent)(({ theme, variant }) => ({
  padding: `${theme.spacing(1)}px 0 0`,
  '&:last-child': {
    paddingBottom: 0,
  },
  ...(variant == 'blogPost' && {
    fontSize: 15,
  }),
}));

const MediaItemContainer = styled('div')(
  ({ theme, isVerticalVideo, isInMediaCarousel }) => ({
    cursor: 'pointer',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    ...(isVerticalVideo && {
      paddingTop: '100%',
    }),
    ...(isInMediaCarousel && {
      cursor: 'grab',
    }),
  })
);

const MediaItemInner = styled('div')(() => ({}));

const MediaItemImage = styled(MuiCardMedia)(
  ({ theme, isInMediaCarousel, isVerticalVideoBackground, src }) => ({
    display: 'block',
    width: '100%',
    ...(isVerticalVideoBackground && {
      backgroundImage: `url("${src}")`,
      width: 'calc(100% + 80px)',
      paddingTop: 'calc(100% + 80px)',
      filter: 'blur(20px)',
      position: 'absolute',
      top: '-40px',
      left: '-40px',
    }),
    ...(isInMediaCarousel && makeMediaCarouselFirstChildStyles(theme)),
  })
);

const MediaItemVerticalVideoThumbnail = styled(MuiCardMedia)(
  ({ theme, src }) => ({
    backgroundImage: `url("${src}")`,
    height: '100%',
    width: '50%',
    position: 'absolute',
    top: '0',
    left: '25%',
    alignItems: 'center',
    borderRadius: '0',
  })
);

const MediaItemVideo = styled(MuiCardMedia)(
  ({ theme, isInMediaCarousel, isVertical }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    ...(isVertical && {
      height: '100%',
      alignItems: 'center',
    }),
    ...(isInMediaCarousel && makeMediaCarouselFirstChildStyles(theme)),
  })
);

const MediaItemProgress = styled('div')({
  left: '50%',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  position: 'absolute',
  color: '#f5f5f5',
  textAlign: 'center',
});

const CarouselContainer = styled('div')({
  '& > div': {
    paddingBottom: '100%',
  },
});

const Carousel = styled(ReCarousel)(({ theme }) => ({
  width: 'calc(100vmin - 48px) !important',
  maxWidth: '100%',
  position: 'absolute !important',
  borderRadius: theme.shape.borderRadius,
  '& div': {
    overflow: 'hidden',
  },
  '& > div:first-child': {
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  },
  '& > div:last-child': {
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
  },
}));

const ContentParagraph = styled(Typography)(() => ({
  '&:last-child': {
    marginBottom: 0,
  },
}));

const ExcerptMoreLink = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  cursor: 'pointer',
}));

const Interactions = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1.25),
  display: 'flex',
}));

const Interaction = styled('span')(({ theme, onClick }) => ({
  border: 0,
  background: 'none',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(2),
  fontWeight: 500,
  fontSize: 'inherit',
  color: 'rgba(0, 0, 0, 0.7)',
  '&:focus': {
    outline: 'none',
  },
  ...(onClick && {
    cursor: 'pointer',
  }),
}));

const Comments = styled('div')(({ theme, show }) => ({
  marginTop: theme.spacing(2.25),
}));

const CommentsInner = styled('div')({
  // opacity: 0,
  // transition: 'all cubic-bezier(0.19, 1, 0.22, 1) 1.4s',
  // transform: `translateY(-${theme.spacing(10)}px)`,
  // ...(presentComments && {
  // opacity: 1,
  // transform: `translateY(0)`,
  // }),
});

const CommentInner = styled('div')(({ theme, owner }) => ({
  display: 'flex',
  marginBottom: theme.spacing(1),
  ...(owner && {
    flexDirection: 'row-reverse',
  }),
}));

const CommentDate = styled('div')(({ theme, owner }) => ({
  color: '#606770',
  padding: '6px 0',
  paddingRight: '6px',
  textAlign: 'right',
  width: 30,
  ...(owner && {
    textAlign: 'left',
    paddingLeft: '6px',
  }),
}));

const CommentBubble = styled('div')(({}) => ({
  display: 'flex',
  maxWidth: '80%',
}));

const CommentBubbleInner = styled('div')(({ theme, owner }) => ({
  lineHeight: '16px',
  borderRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius / 4,
  background: '#f1f0f0',
  color: '#444950',
  padding: '6px 10px',
  overflowWrap: 'break-word',
  ...(owner && {
    background: '#0084ff',
    color: '#fff',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius / 4,
  }),
}));

const CommentWhitespace = styled('div')(({ theme }) => ({
  alignItems: 'center',
  background: 'white',
  display: 'flex',
  flex: '1 1 0%',
  float: 'none',
  justifyContent: 'flex-end',
}));

/**
 * Removes right sided border radius on the first carousel media item
 * @param {Object} theme
 */
function makeMediaCarouselFirstChildStyles(theme) {
  return {
    '&:first-child': {
      borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    },
  };
}

export default FeedItem;
