import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import { styled, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiCard from '@material-ui/core/Card';
import MuiCardMedia from '@material-ui/core/CardMedia';
import SlickSlider from 'react-slick';
import Typography from '@material-ui/core/Typography';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function PromoItems(props) {
  const theme = useTheme();
  const screenMediumAndUp = useMediaQuery(theme.breakpoints.up('md'));

  function getSlidesToShow() {
    if (screenMediumAndUp) {
      return 3;
    } else {
      return 1;
    }
  }

  const PromoItemLink = React.forwardRef(function Link(props, ref) {
    return <Card component={GatsbyLink} ref={ref} {...props} />;
  });

  function PromoItem(props) {
    return (
      <PromoItemLink to={props.item.link?.slug ?? '#'}>
        <PromoItemInner image={props.item.media.sizes.medium?.url}>
          <PromoItemOverlay darkness={props.item.darkness} />
          <PromoItemContent>
            {props.item.title && (
              <PromoItemTitle>{props.item.title}</PromoItemTitle>
            )}
          </PromoItemContent>
        </PromoItemInner>
      </PromoItemLink>
    );
  }

  PromoItem.propTypes = {
    item: PropTypes.object.isRequired,
  };

  return (
    <Container>
      <Slider
        dots={false}
        infinite={true}
        speed={300}
        slidesToShow={getSlidesToShow()}
        slidesToScroll={1}
        swipeToSlide={true}
        arrows={false}
      >
        {props.items.map((promoItem, index) => {
          return <PromoItem item={promoItem} key={index} />;
        })}
      </Slider>
    </Container>
  );
}

PromoItems.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

const Container = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  [theme.breakpoints.up('sm')]: {
    margin: `0 ${-theme.spacing(1.5)}px`,
  },
  [theme.breakpoints.up('xl')]: {
    margin: `0 ${-theme.spacing(2)}px`,
  },
}));

const Slider = styled(SlickSlider)(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(2)}px)`,
  // marginLeft: -theme.spacing.unit,
  // width: '100%',
  lineHeight: 0,
  margin: '0 auto',
  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
  '& .slick-list': {
    overflow: 'visible',
  },
  '& .slick-slide': {
    padding: `0 ${theme.spacing(2)}px`,
    // padding: `0 ${theme.spacing(1)}px`,
    [theme.breakpoints.up('sm')]: {
      // padding: `0 ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.up('xl')]: {
      // padding: `0 ${theme.spacing(2)}px`,
      // '&:nth-child(4)': {
      //   opacity: 0,
      // },
    },
  },
}));

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'block',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const PromoItemContent = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3),
  zIndex: 20,
}));

const PromoItemTitle = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontSize: theme.typography.h5.fontSize,
  fontWeight: 700,
}));

const PromoItemInner = styled(MuiCardMedia)(({ theme }) => ({
  position: 'relative',
  '&:before': {
    content: '""',
    display: 'block',
    paddingTop: `${(10 / 10) * 100}%`,
  },
}));

const PromoItemOverlay = styled('div')(({ darkness }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 10,
  background: `rgba(0, 0, 0, ${darkness / 100})`,
}));

export default PromoItems;
