import React from 'react';
import commentIconSrc from './CommentIcon.svg';
import { styled } from '@material-ui/styles';

export default () => {
  return <Icon src={commentIconSrc} alt="Comment icon" />;
};

const Icon = styled('img')({
  display: 'block',
});
