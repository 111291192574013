import React from 'react';
import likeIconSrc from './LikeIcon.svg';
import { styled } from '@material-ui/styles';

export default () => {
  return <Icon src={likeIconSrc} alt="Like icon" />;
};

const Icon = styled('img')({
  display: 'block',
});
