import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  playButtonCircle: {
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    zIndex: 100,
    position: "absolute",
    boxSizing: "border-box",
    display: "block",
    width: "80px",
    height: "80px",
    paddingTop: "14px",
    paddingLeft: "8px",
    lineHeight: "20px",
    border: "6px solid #fff",
    borderRadius: "50%",
    color: "#f5f5f5",
    textAlign: "center",
    textDecoration: "none",
    backgroundColor: "rgba(0,0,0,0.5)",
    transition: "all 0.3s ease"
  },
  trianglePlay: {
    borderTop: "20px solid transparent",
    borderBottom: "20px solid transparent",
    borderLeft: "30px solid white",
    position: "absolute",
    left: "calc(50% + 4px)",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  }
});

const MediaStartIcon = props => {
  const { classes } = props;

  return (
    <div className={classes.playButtonCircle}>
      <div className={classes.trianglePlay} />
    </div>
  );
};

export default withStyles(styles)(MediaStartIcon);
