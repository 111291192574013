import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import FeedItem from '../FeedItem/FeedItem';
import R from 'ramda';

function Feed(props) {
  const [feed, setFeed] = useState(props.feed);
  const hasMoreFeedItems = feed.length < props.totalFeedItems;
  const feedItemsLoadAmount = 5;

  function calculateFeedItemsOffset(page = 2) {
    return (
      props.initialFeedLength - feedItemsLoadAmount + page * feedItemsLoadAmount
    );
  }

  async function loadMoreFeedItems(page) {
    const offset = calculateFeedItemsOffset(page);
    const response = await axios.get(
      `${props.apiUrl}/wp-json/wp/v2/posts?per_page=${feedItemsLoadAmount}&offset=${offset}`
    );

    if (response.status == 200) {
      const newFeed = feed.concat(response.data);
      setFeed(newFeed);
    }
  }

  function getAccountByOriginAndId(origin, from, accounts) {
    const isOrigin = R.propEq('origin', origin);
    const isAccountId = R.propEq('id', parseInt(from));
    const isOriginAndAccountId = R.allPass([isOrigin, isAccountId]);
    const account = R.head(R.filter(isOriginAndAccountId, accounts));

    return account;
  }

  console.log('feed :>> ', feed);

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMoreFeedItems}
      initialLoad={false}
      hasMore={hasMoreFeedItems}
      threshold={4000}
    >
      {feed.map((post) => {
        const account = getAccountByOriginAndId(
          post.origin,
          post.from,
          props.accounts
        );

        const type = post['__real_type']; // This is as long as we're using WP since WP wont let us have a custom post prop called 'type' without breaking admin UI
        const amendedItem = R.assoc('type', type, post);

        console.log('REAL TYPE', type);

        return (
          <FeedItem
            item={amendedItem}
            account={account}
            apiUrl={props.apiUrl}
            key={post.id}
          />
        );
      })}
    </InfiniteScroll>
  );
}

Feed.propTypes = {
  accounts: PropTypes.array.isRequired,
};

export default Feed;
